@font-face {
  font-family: 'HurmeGeometric';
  src: url('./assets/HurmeGeometricSans2/HurmeGeometricSans2Black.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'HurmeGeometric';
  src: url('./assets/HurmeGeometricSans2/HurmeGeometricSans2Bold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'HurmeGeometric';
  src: url('./assets/HurmeGeometricSans2/HurmeGeometricSans2.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'HurmeGeometric';
  src: url('./assets/HurmeGeometricSans2/HurmeGeometricSans2Light.otf');
  font-weight: 200;
}

.light {
  --primary-background: white;
  --text-color: #1e1e1e;
  --secondary-text-color: #494949;
  --secondary-background: #eeecec;
  --accent-color: #00ccff;
  --nav-color: #ffffff;
  --invert: 0%;
}

.dark {
  --primary-background: #383838;
  --text-color: #e6e6e6;
  --secondary-text-color: #ffffff;
  --secondary-background: #515151;
  --accent-color: #00B8E0;
  --nav-color: #272727;
  --invert: 100%;
}

body {
  overflow-x: hidden;
  margin: 50px 0 0 0;
  background-color: #383838;
}

* {
  font-family: "HurmeGeometric";
  transition: background-color 0.2s, color 0.2s;
  color: var(--text-color);
}

@media screen and (max-width:830px){
  body {
    margin: 0 0 50px 0;
  }
}